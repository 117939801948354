import React, { useMemo } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import MetaData from "../components/meta"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Styles
import "../styles/glossary.scss"

// Images
import ctaRequestDemo from "../images/glossary/request-a-demo.svg"
import ctaFreeTrial from "../images/glossary/free-trial.svg"
import heroIllustrationLeft from "../images/glossary/illustration-left.png"
import heroIllustrationRight from "../images/glossary/illustration-right.png"

const GlossaryPage = ({ data, location }) => {
  const glossaryPosts = data.glossaryPosts.edges

  const dictionary = useMemo(() => {
    let postsSorted = glossaryPosts.sort((a, b) => {
      if (a.node.title.toLowerCase() < b.node.title.toLowerCase()) {
        return -1
      }
      if (a.node.title.toLowerCase() > b.node.title.toLowerCase()) {
        return 1
      }
      return 0
    })

    let dictionary = {}
    let currentLetter = ""

    postsSorted.forEach(post => {
      let letter = post.node.title.toLowerCase().charAt(0)
      if (letter !== currentLetter) {
        currentLetter = letter
        dictionary[letter] = []
      }
      dictionary[letter].push(post)
    })

    return dictionary
  }, [data.glossaryPosts.edges])

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.currentPage,
        }}
      />
      <div className="glossary-page">
        {/* Header */}
        <section className="glossary-header page-header">
          <StaticImage
            src="../images/glossary/post-default-header-bg.png"
            alt=""
            layout="fullWidth"
            style={{
              position: "absolute",
              top: 0,
              width: "100%",
              height: "100%",
            }}
            quality={90}
            placeholder="dominantColor"
          />
          <div className="glossary-post-header__images">
            <img
              src={heroIllustrationLeft}
              alt=""
              style={{
                position: "absolute",
                height: "100%",
                left: 0,
                top: 0,
              }}
            />
            <img
              src={heroIllustrationRight}
              alt=""
              style={{
                position: "absolute",
                height: "100%",
                right: 0,
                top: 0,
              }}
            />
          </div>

          <div className="wrapper-1200">
            <h1>Cloud Native Networking 101</h1>
            <p>Common terminologies explained.</p>
          </div>
        </section>

        {/* Posts */}
        <section className="glossary-items page-content-section">
          <div className="wrapper-800">
            {Object.keys(dictionary).map(letter => (
              <Section key={letter}>
                <Alphabet>{letter}</Alphabet>
                <List>
                  {dictionary[letter].map(({ node }) => (
                    <ListItem key={node.id}>
                      <Link to={`/glossary/${node.slug}/`}>{node.title}</Link>
                    </ListItem>
                  ))}
                </List>
              </Section>
            ))}
          </div>
        </section>

        {/* CTA */}
        <section className="glossary-cta page-footer">
          <StaticImage
            src="../images/glossary/cta-bg.png"
            alt=""
            layout="fullWidth"
            style={{
              position: "absolute",
              top: 0,
              width: "100%",
              height: "100%",
            }}
            quality={90}
            placeholder="dominantColor"
          />
          <div className="wrapper-1200">
            <h2>Start your journey today</h2>
            <div className="cta-link-cards">
              <div className="cta-link-card cta-link-card--no-gradient">
                <div className="link">
                  <a href="https://info.traefik.io/en/request-demo-traefik-enterprise/">
                    Request a demo &rarr;
                  </a>
                </div>
                <div className="image">
                  <img src={ctaRequestDemo} />
                </div>
              </div>
              <div className="cta-link-card cta-link-card--no-gradient">
                <div className="link">
                  <a href="https://info.traefik.io/get-traefik-enterprise-free-for-30-days">
                    Free trial &rarr;
                  </a>
                </div>
                <div className="image">
                  <img src={ctaFreeTrial} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

const Section = styled.div`
  display: grid;
  grid-template-columns: 60px auto;
  margin: 30px 0 0;

  &:first-child {
    margin: 0;
  }

  &:last-child {
    ul {
      border-bottom: 0;
    }
  }
`

const Alphabet = styled.h2`
  color: ${props => props.theme.colors.text.light};
  text-transform: uppercase;
  font-size: 1.5rem;
  margin: 0;
  padding: 8px 0 0;
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 30px;
`

const ListItem = styled.li`
  margin: 16px 0 0;
  padding: 0;
  line-height: 1.4em;

  &:hover {
    &::marker {
      color: ${props => props.theme.colors.primary.base};
    }
  }

  a {
    color: ${props => props.theme.colors.text.dark};

    &:hover {
      color: ${props => props.theme.colors.primary.base};
    }
  }

  &:first-child {
    margin: 0;
  }
`

export default GlossaryPage

export const query = graphql`
  query {
    currentPage: ghostPage(slug: { eq: "glossary" }) {
      ...GhostPageFields
    }

    glossaryPosts: allGhostPost(
      filter: { primary_tag: { slug: { eq: "glossary" } } }
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`
